 .MortCalc {

    width: 100%;
    height: 100vh;
        padding: 0px;
        overflow-y: scroll;
        /* display: flex;
        flex-direction: column; */
    background: white;


} 

  @media only screen and (min-width: 1024px) {

    .MortCalc {
        display: flex;
        flex-direction: row;

    }


}
/*
@media only screen and (min-width: 1024px) {

    .MortCalc {
        max-width: 100%;
    }

  
} */
/* override browser default */
* {
  box-sizing: border-box;
    padding: 0;
    margin: 0;
}

/* use viewport-relative units to cover page fully */
body {
  height: 100vh;
  width: 100vw;
}

body {
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:inherit;
    vertical-align:top;
    background:transparent;
    color: inherit;
}

body{
  font-size: 11px;
}

h1 { 
  font-size: 1.25em;
  margin-bottom: 2em;
}

h2 { 
  margin-bottom: 2em;

}
h3 { 
  font-size: 2.25em;
  margin-bottom: 1em;
}
input {
  font-size: 2.25em;

}

input {
  width: 100%;
  background: none;
  text-align: left;
  font-weight: bold;
  border: none;
  margin-bottom: 0.5em;

  }


input[type='range'] {
  -webkit-appearance: none;
 
  width: 100%;
  height: 3px;
  border-radius: 5px;  
  background:black;
  margin-bottom: 1em;

}

input[type='range']::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%; 
  background: #dedbd4;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #dedbd4;
  cursor: pointer;
}


/* :before = box */
input[type='checkbox']:after
{
  position: relative;
  top: -1.11em;
  display: inline-block;
  content: '';
  width: 0.9em;
  height: 0.9em;
  background-color: #dedbd4;
  border-radius: 50%;
  margin: 3px;
  transition: all 150ms;

}
/* :after = slider */
input[type='checkbox']:before
{

  display: block;
  content: '';
  width: 2em;
  height: 1em;
  background-color: black;
  padding-left: 0.2em;
  padding-bottom: 0.1em;
  border-radius: 1em;
  -webkit-transition: all 125ms;
  transition: all 125ms;
  
}



input[type='checkbox']:checked:before
{
  background-color: #419daa;
}


input[type='checkbox']:checked::after{
  background-color: white;
    margin-left: 1.2em;


}

input[type='checkbox']
{
  
  width: 0;
  height: 0;
  max-width:0px;
  max-height: 0px;
  -webkit-appearance: none;


}



/* input[type='checkbox']:checked:after
{
  position: relative;
  display: block;
  content: '';
  width: 1em;
  height: 1em;
  background-color: #dedbd4;
  border-radius: 50%;
  position: absolute;

} */


label 
{
  display: block;
    width: 100%;
  font-weight: bold;
  margin-bottom: 1em;
}

sup { 
  position: relative;
   font-size: 0.75em; 
   padding-right: 2px;
   top:0.3em;
}


/*-----------------*/




.column_1, .column_2 {
    display: inline-block;
    width: 50%;
    /* height: 30vh; */
    /* padding:  0% 5% ; */
    /* border: 1px red solid; */


}

.column_1 {
    padding-right: 5%  ;
    /* border: 1px red solid; */
}

.column_2 {
    padding-left: 5%  ;

}
.panel_2 {
    /* background-color: aqua; */
}
/*  IPAD PORTRAIT  */

@media  screen and (min-width: 768px) and (max-width: 1023px) {

    .title {
        margin: 0;
    }



    .panel_1{
        display: flex;
        flex-direction: row;
        margin-left: 60px;

    }

    .panel_1 div{
        flex: 1;
    }

    .panel_2 {
        height: 100%;
        /* min-height: 50vh;
            max-height: 50vh; */
        margin-left: 60px;
    }

   

}

/*  DESKTOP  */


@media  screen and (min-width: 1024px) {




  .panel_1, .panel_2 {
      width: 50%;
      overflow-y: scroll;

  }

  .panel_1{
        display: flex;
        flex-direction: column;
        margin-left: 60px;
        max-width:fit-content;
    }

    .panel_1 > div{
        flex: 1;

    }


  .panel_2 {

      overflow:hidden;
  }


}


/*  X WIDE DESKTOP  */


@media   (max-height:950px) and (min-width: 1400px) {

   .panel_1{
       width: 60%;
       flex-direction: row;

   }
   .panel_1 > div{
    flex: 1;
    overflow-y: scroll;
}
   .panel_2{
       width: 40%;
       flex: 1;


   }


}
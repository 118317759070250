.MortSummaryPanel
{
    padding: 32px;
    background: #dedbd4;
    /* transition: max-height 0.4s linear ; */
    width: 100%;


}



.MortSummaryPanel label {    color: #99907c;
}


    .NavBar {
        background: #151c22;
        display: flex;
        z-index: 99;
        
    }

    .NavItems {
        background: #151c22;
    }

 

    .Logo
    {
        width: 150px;
    }

  
    .Menu {
        width:28px;
       cursor: pointer;
       }



    .nav1  {
        display:flex;
        justify-content:space-between;
        padding: 10px;

    }

    /* .nav1, .nav2 {

        border: orange 1px solid;
    }
 */

 


 @media  screen and (max-width: 767px) {

        .NavBar{
            flex-direction: column;
            width: 100%;
        }

        .NavBar.active{
            position: fixed;
        }

        .NavItems {
            position: fixed;
            top:58px;
            padding-left: 22px;
            background: #151c22;
            max-height: 0px;
            height: auto;
            width: 100%;
            overflow: hidden;
            z-index: 88;
            transition: max-height 0.2s ease;

        }

        .NavItems.active{

            max-height: 75px;
            padding-bottom: 10px;

        }
     
        .NavSpace.active {
            height: 60px;
        }



        .Logo {
            margin-top: 12px;
            margin-left: 12px;
        }


         

      }



@media  screen and (min-width: 768px) {
 
    .NavBar {
        position: fixed;
        width: 60px;
        height: 100%;
        max-height: 100%;
        flex-direction: row;
        align-items: stretch;
        overflow: hidden;     
    }


    .NavItems {
        position: fixed;
        width: 0;
        overflow-y: hidden;
        height: 100%;
        padding-top:120px;
        left: 60px;
        z-index: 88;
        transition: width 0.2s ease;

    }

    .NavItems.active {
        width: 325px;

    }


    .nav1 {
            height: 100%;
            flex-direction: column-reverse;
            align-items: center;
            width: 60px;
          
        }

      
        


       
        .Logo
        {
            margin-bottom: 80px;

            transform: rotate(270deg);
        }

}


li {
    font-size: 2em;
    color: #e6686c ;
    list-style-type: none;
    display: block;
    min-width: 325px;
}



.modal {
    position: fixed;
    background: black;
    visibility: hidden;
    width: 100%;
    height: 100%;
    z-index: 55;
    /* transition: opacity 0.2s ease; */


}
  


.bar1, .bar2, .bar3 {
    width: 28px;
    height: 2px;
    background-color: #e6686c;
    margin: 8px 0;
    transition: 0.2s;
  }
  
  /* Rotate first bar */
.active .bar1 {
    -webkit-transform: rotate(-45deg) translate(-7px, 6px) ;
    transform: rotate(-45deg) translate(-7px, 6px) ;
  }
  
  /* Fade out the second bar */
.active .bar2 {
    opacity: 0;
  }
  
  /* Rotate last bar */
.active .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
    transform: rotate(45deg) translate(-8px, -8px) ;
  }

  .modal.active{
      visibility: visible;
      opacity: 0.85;
  }

.MortGraphPanel {


    padding: 32px;
    height: 100%;
    max-height: fit-content;
    overflow: hidden; 
    max-height: 85vh;

        /* border:1px orange solid; */

    /* background: #ece9e5; */
    /* max-height: 5.5em;
    transition: max-height 0.4s linear ;
    overflow: hidden; */
    /* height: 100%;
    width: 100%;
    min-height: 50vh;
    max-height: 85vh; */

}




.header h1 {
    cursor: pointer;
}



.header:before
{
    content: '+';
    float: right;
    font-size: 1.5em;
    z-index: -1;
}


.open  .header:before {
    content: '-';


}


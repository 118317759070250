.MortInputPanel {
    display: inline-block;
    padding: 32px;
    background: #22292f;
    color: white;
    width: 100%;
    
}

.MortInputPanel input {
  color: white;
}

.MortInputPanel label {

color: PowderBlue ;
cursor: pointer;
}

.MortInputPanel h2 {
  cursor: pointer;


}




